<template>
  <h4 class="text-center">
    <label-theme-component>
      {{
        textRecall
          ? $t('callcenter.infoProcessing.' + textRecall) + ' ' + deadline + ' ' + $t('common.minutes')
          : 'N.D.'
      }}
    </label-theme-component>
  </h4>
</template>

<script>

import {mapGetters} from "vuex";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "OperatorWizardStepThreeRecall",
  components: {LabelThemeComponent},
  data() {
    return {
      leadStatus: LeadStatuses
    }
  },

  computed: {

    ...mapGetters({
      subStatusTo: 'call-center-operator/subStatusTo',
      settings: 'common/settings',
    }),

    textRecall: function () {
      switch (this.subStatusTo) {
        case this.leadStatus.processing.substatuses.not_answer.value:
          return 'not_answer';
        case this.leadStatus.processing.substatuses.unattainable.value:
          return 'unattainable';
        case this.leadStatus.processing.substatuses.busy.value:
          return 'busy';
        default:
          return null

      }
    },

    deadline: function () {
      const deadlines =  this.settings?.callcenter_deadlines;

      switch (this.subStatusTo) {
        case this.leadStatus.processing.substatuses.not_answer.value:
          return deadlines?.not_answer?.minutes;
        case this.leadStatus.processing.substatuses.unattainable.value:
          return deadlines?.unattainable?.minutes;
        case this.leadStatus.processing.substatuses.busy.value:
          return deadlines?.busy?.minutes;
        default:
          return 'N.D.'
      }
    }
  }
}
</script>

<style scoped>

</style>
