<template>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12 list-group-flush">
        <list-group-title-section-component :label="$t('callcenter.lead_data')"/>
      </div>
      <div class="col-12">
        <ul class="list-group list-group-flush">
          <list-group-item-component
            :label="$t('fields.firstname')"
            :value="`${$options.filters.optional(registry, 'name')} ` +
                    `${$options.filters.optional(registry, 'surname')}`"
          />
          <list-group-item-component v-if="statusTo"
                                     :label="$t('common.status')"
                                     :value="$t('callcenter.status.' + statusTo)"
          />
          <list-group-item-component v-if="subStatusTo"
                                     :label="$t('common.sub_status')"
                                     :value="$t('callcenter.status.' + subStatusTo)"
          />
          <list-group-item-component :label="$t('common.courses')">
            <template slot="value">
              <div class="d-flex flex-column">
                <div v-for="(item, index) in courses" v-bind:key="`course-1-${index}`">
                  <label-theme-component>{{ item.title }}</label-theme-component>
                </div>
              </div>
            </template>
          </list-group-item-component>
        </ul>
      </div>
    </div>
    <div class="row" v-if="subStatusTo === leadStatuses.promoted.substatuses.appointment_confirmed.value">
      <div class="col-12 list-group-flush">
        <list-group-title-section-component label="appuntamento"/>
      </div>
      <div class="col-12">
        <operator-wizard-step-three-appointment-confirmed/>
      </div>
    </div>
    <div class="row">
      <div class="col-12 list-group-flush">
        <list-group-title-section-component :label="$t('fields.notes')"/>
      </div>
      <div class="col-12 mx-2 white-space-pre">
        <label-theme-component>
          {{ lead.notes }}
        </label-theme-component>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import OperatorWizardStepThreeAppointmentConfirmed
  from "../../CallCenter/OperatorComponents/OperatorWizardStepThreeAppointmentConfirmed";
import LeadStatuses from "../../CallCenter/resources/leadStatuses";

export default {
  name: "ConfirmHandleLeadModal",
  components: {
    OperatorWizardStepThreeAppointmentConfirmed,
    LabelThemeComponent,
    ListGroupTitleSectionComponent,
    ListGroupItemComponent
  },
  data() {
    return {
      leadStatuses: LeadStatuses
    }
  },
  computed: {
    ...mapGetters({
      lead: 'call-center-operator/lead',
      registry: 'call-center-operator/registry',
      courses: 'call-center-operator/courses',
      statusTo: 'call-center-operator/statusTo',
      subStatusTo: 'call-center-operator/subStatusTo',
      saleAppointment: 'call-center-operator/saleAppointment',
      skipIntro: 'call-center-operator/skipIntro',
    }),
  },
}
</script>

<style scoped>

</style>
