<template>
    <card body-classes="standard-card-body" class="shadow">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('callcenter.lead_data') }}</h4>
      </div>
      <ul class="list-group list-group-flush">
        <list-group-item-component :label="$t('fields.date')" :value="lead.date_in | date"/>
        <list-group-item-component
          v-if="permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])"
          :label="$t('common.provider')" :value="provider | optional('name')"
        />
        <list-group-item-component :label="$t('common.campaign')" :value="campaign | optional('name')"/>
        <list-group-item-component :label="$t('common.courses')">
          <template slot="value">
            <div class="d-flex flex-column">
              <label-theme-component v-for="course in courses" v-bind:key="course.code">
                {{ course.title }}
              </label-theme-component>
            </div>
          </template>
        </list-group-item-component>
      </ul>
      <hr>
      <base-text-area :label="$t('fields.notes')" :rows="noteRows" v-model="lead.notes"/>
    </card>
</template>

<script>
import {mapGetters} from "vuex";
import {permissionService} from "@/util/permission-service";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "OperatorTabLead",
  components: {BaseTextArea, LabelThemeComponent, ListGroupItemComponent},
  data() {
    return {
      permissionService: permissionService
    }
  },
  props: {
    noteRows: {
      type: Number,
      default: 10
    }
  },
  computed: {
    ...mapGetters({
      provider: 'call-center-operator/provider',
      campaign: 'call-center-operator/campaign',
      courses: 'call-center-operator/courses',
      lead: 'call-center-operator/lead',
    }),
  }
}
</script>

<style scoped lang="scss">

</style>
