<template>
  <div class="container-fluid ">
    <div v-if="showGetStarted" class="row justify-content-center align-items-center operator-container">
      <div class="col-md-10">
        <card class="shadow-lg" body-classes="standard-card-body" footer-classes="pt-0">
          <operator-data/>

          <div slot="footer" class="text-center">
            <base-button
              link
              class="text-uppercase"
              @click="getNextLead">
              {{ $t('callcenter.get_started') }}
            </base-button>
          </div>
        </card>
      </div>
    </div>
    <div v-if="!showGetStarted" class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <card class="card shadow" body-classes="standard-card-body">
              <div slot="header">
                <h4 class="text-uppercase card-title">{{ $t('callcenter.actions') }}</h4>
              </div>
              <operator-action-container
                :phones="phones"
                :emails="emails"
                :lead="lead"
                :registry="registry"
                :appointment-id="saleAppointment.id"
                :course-ids="courseIds"
                :key="`operator-action-container-${nextLeadKey}`"
                @onStartCall="leadHistoryKey++"
                @onEmailSent="leadHistoryKey++"
              />
            </card>
          </div>
          <div class="col-md-4">
            <card class="card shadow" body-classes="standard-card-body">
              <div slot="header">
                <h4 class="text-uppercase card-title">{{ $t('callcenter.change_status') }}</h4>
              </div>
              <change-lead-status/>
            </card>
          </div>
          <div class="col-md-4">
            <card
              class="card shadow other-card"
              body-classes="standard-card-body d-flex align-items-center justify-content-center"
            >
              <div slot="header">
                <h4 class="text-uppercase card-title">{{ $t('callcenter.other') }}</h4>
              </div>
              <h4 v-if="!stepThreeComponent">
                <label-theme-component>
                  {{ $t('common.actions_not_necessary') }}
                </label-theme-component>
              </h4>
              <component class="w-100" :is="stepThreeComponent"></component>
            </card>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="lead.leadEventLogsCount >= 10">
        <base-alert type="danger" class="text-center">
          {{ $t("callcenter.lead_too_many_status_transitions") }}
        </base-alert>
      </div>
      <div class="col-12" v-if="showMultipleRegistryHistoriesAlert">
        <base-alert type="warning" class="text-center">
          {{ $t("callcenter.lead_multiple_registry_histories") }}
        </base-alert>
      </div>
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-6">
                <operator-tab-lead :noteRows="20"/>
              </div>
              <div class="col-md-6">
                <registry-personal-data
                  save-self
                  :registry="registry"
                  :key="`data-${nextLeadKey}`"
                />
                <octo-entity-addresses
                  save-self
                  :addresses="addresses"
                  :key="`addresses-${nextLeadKey}`"
                  :registry-id="registry.id"
                />
                <octo-entity-emails
                  save-self
                  :key="`emails-${nextLeadKey}`"
                  :emails="emails"
                  :registry-id="registry.id"
                  @onSetEmails="setEmails"

                />
                <octo-entity-phones
                  save-self
                  :key="`phones-${nextLeadKey}`"
                  :phones="phones"
                  :registry-id="registry.id"
                  @onSetPhones="setPhones"
                />
              </div>
            </div>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.organizer') }}</div>
              </div>
            </template>
            <lead-scheduler/>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.history_lead') }}</div>
              </div>
            </template>
            <div class="row">
              <div class="col-md-6">
                <card class="card-plain">
                  <div slot="header">
                    <h3 class="text-uppercase card-title">{{ $t('callcenter.history_lead') }}</h3>
                  </div>
                  <lead-history :lead-id="lead | optional('id')"
                                :key="`lead-history-${leadHistoryKey}`"/>
                </card>
              </div>
              <div class="col-md-6">
                <card class="card-plain">
                  <div slot="header">
                    <h3 class="text-uppercase card-title">{{ $t('callcenter.history_registry') }}</h3>
                  </div>
                  <registry-history
                    :registry-id="registry.id"
                    @onMultipleRegistryHistories="showMultipleRegistryHistoriesAlert = true"
                  />
                </card>

              </div>
            </div>
          </tab-pane>

        </tabs>
      </div>
      <div class="col-md-12">
        <base-button
          :disabled="!enableLeadSaving"
          @click="showConfirmModal = true"
          block class="text-uppercase" size="lg">
          {{ $t('callcenter.save_lead') }}
        </base-button>
      </div>
    </div>
    <modal :show.sync="showConfirmModal" modalClasses="modal-lg" bodyClasses="p-1">
      <confirm-handle-lead-modal />
      <template slot="header">
        <h5 class="modal-title text-uppercase">
          {{ $t('confirm.operator_update_lead_title') }}
        </h5>
      </template>
      <template slot="footer">
        <base-button link class="text-capitalize" @click="saveLead">
          {{ $t('common.update') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {TabPane, Tabs} from "@/components";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";
import RegistryHistory from "@/pages/Registries/components/RegistryHistory";
import OperatorData from "@/pages/CallCenter/OperatorComponents/OperatorData";
import OperatorTabLead from "@/pages/CallCenter/OperatorComponents/OperatorTabLead";
import RegistryPersonalData from "@/pages/Registries/components/RegistryPersonalData";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import OperatorActionContainer from "@/pages/Operator/component/OperatorActionContainer";
import ChangeLeadStatus from "@/pages/Operator/component/ChangeLeadStatus";
import LeadScheduler from "@/pages/Operator/component/LeadScheduler";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";
import OperatorWizardStepThreeRecall from "@/pages/CallCenter/OperatorComponents/OperatorWizardStepThreeRecall";
import OperatorWizardStepThreeRecallDeadline
  from "@/pages/CallCenter/OperatorComponents/OperatorWizardStepThreeRecallDeadline";
import OperatorWizardStepThreeAppointmentConfirmed
  from "@/pages/CallCenter/OperatorComponents/OperatorWizardStepThreeAppointmentConfirmed";
import Modal from "@/components/Modal";
import ConfirmHandleLeadModal from "./component/ConfirmHandleLeadModal";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import BaseAlert from "@/components/BaseAlert";
import OctoRichText from "@/components/octo-rich-text/OctoRichText";
import {MessageBox} from "element-ui";

export default {
  name: "HandleLeadPage",
  components: {
    OctoRichText,
    BaseAlert,
    LabelThemeComponent,
    ConfirmHandleLeadModal,
    Modal,
    LeadScheduler,
    ChangeLeadStatus,
    OperatorActionContainer,
    OctoEntityPhones,
    OctoEntityEmails,
    OctoEntityAddresses,
    RegistryPersonalData,
    OperatorTabLead,
    OctoIcon,
    OperatorData,
    TabPane,
    Tabs,
    LeadHistory,
    RegistryHistory
  },
  data() {
    return {
      showGetStarted: true,
      showConfirmModal: false,
      endpoints: endpoints,
      nextLeadKey: 1,
      leadHistoryKey: 1,
      stepThreeComponent: null,
      showMultipleRegistryHistoriesAlert: false,
    }
  },
  computed: {
    ...mapGetters({
      lead: 'call-center-operator/lead',
      callCenterLead: 'call-center-operator/callCenterLead',
      registry: 'call-center-operator/registry',
      phones: 'call-center-operator/phones',
      addresses: 'call-center-operator/addresses',
      emails: 'call-center-operator/emails',
      campaign: 'call-center-operator/campaign',
      statusTo: 'call-center-operator/statusTo',
      subStatusTo: 'call-center-operator/subStatusTo',
      deadline: 'call-center-operator/deadline',
      saleAppointment: 'call-center-operator/saleAppointment',
      skipIntro: 'call-center-operator/skipIntro',
      courses: 'call-center-operator/courses',
    }),

    stepTwoCompleted: function () {
      return !!this.subStatusTo;
    },

    stepThreeCompleted: function () {
      switch (this.subStatusTo) {
        case LeadStatuses.processing.substatuses.private_recall.value:
        case LeadStatuses.processing.substatuses.public_recall.value:
          return !!this.deadline;

        case LeadStatuses.promoted.substatuses.appointment_confirmed.value:
          return !this.$_.isEmpty(this.saleAppointment);

        default:
          return true;
      }
    },

    enableLeadSaving: function () {
      return this.stepTwoCompleted && this.stepThreeCompleted;
    },

    courseIds() {
      return this.$_.map(this.courses, course => {
        return course.id
      });
    },
  },
  watch: {
    subStatusTo: function (val) {
      switch (val) {
        case LeadStatuses.processing.substatuses.busy.value:
        case LeadStatuses.processing.substatuses.unattainable.value:
        case LeadStatuses.processing.substatuses.not_answer.value:
          this.stepThreeComponent = OperatorWizardStepThreeRecall;
          break;

        case LeadStatuses.processing.substatuses.private_recall.value:
        case LeadStatuses.processing.substatuses.public_recall.value:
          this.setDeadline('');
          this.stepThreeComponent = OperatorWizardStepThreeRecallDeadline;
          break;

        case LeadStatuses.promoted.substatuses.appointment_confirmed.value:
          this.stepThreeComponent = OperatorWizardStepThreeAppointmentConfirmed;
          break;

        default:
          this.stepThreeComponent = null;
          break;

      }
    },
  },
  async mounted() {
    if (this.skipIntro) {
      this.setSkipIntro(false);
      await this.getNextLead();
    }
  },

  methods: {
    ...mapMutations({
      setEmails: 'call-center-operator/setEmails',
      setPhones: 'call-center-operator/setPhones',
      setSkipIntro: 'call-center-operator/setSkipIntro',
      setDeadline: 'call-center-operator/setDeadline',
      resetState: 'call-center-operator/resetState',
    }),

    ...mapActions({
      getFirstLead: 'call-center-operator/getFirstLead',
      getCampaignSales: 'call-center-operator/getCampaignSales',
      handleLead: 'call-center-operator/handleLead'
    }),

    getNextLead: async function () {

      try {
        this.$fullLoading.show();
        await this.resetState();
        await this.getFirstLead();
        await this.getCampaignSales(this.campaign);
        this.showGetStarted = false;
        this.showMultipleRegistryHistoriesAlert = false;
        this.nextLeadKey++;
      } catch (e) {
        MessageBox({
          title: this.$t('common.attention'),
          message: this.$t('callcenter.no_lead_available'),
          confirmButtonText: this.$t('common.ok'),
          type: 'warning'
        })
          .finally(() => {
            this.showGetStarted = true
          });
      } finally {
        this.$fullLoading.hide();
      }
    },

    saveLead: function () {
      this.$fullLoading.show();

      let leadData = {
        statusTo: this.statusTo,
        subStatusTo: this.subStatusTo,
        deadline: this.$_.isEmpty(this.deadline)
          ? null
          : this.$moment(this.deadline).format('YYYY-MM-DD HH:mm:ss'),
        leadNote: this.lead?.notes || null,
        statusNote: ""
      }

      if (this.subStatusTo === LeadStatuses.promoted.substatuses.appointment_confirmed.value) {
        leadData.appointment = this.saleAppointment;
      }

      this.handleLead({data: leadData, id: this.callCenterLead.id})
        .then(() => {
          this.showConfirmModal = false;
          this.showGetStarted = true;
          this.showMultipleRegistryHistoriesAlert = false;
        })
        .catch((e) => {

          this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
          if(e?.response?.data?.message === 'lead_owner_mismatch') {
            MessageBox({
              title: this.$t('notifications.saved_error'),
              message: this.$t('notifications.lead_owner_mismatch'),
              confirmButtonText: this.$t('common.close'),
              type: 'warning'
            });
          }
        })
        .finally(() => {
          this.$fullLoading.hide()
        })
    }
  }
}
</script>

<style scoped lang="scss">
.operator-container {
  min-height: calc(100vh - 85px);
}

.other-card {
  min-height: 180px;
}
</style>
