<template>
  <div v-observe-visibility="(isVisible, entry) => this.showScheduler = isVisible">
    <deal-date-scheduler-legend v-if="enableStatuses"/>
    <deal-date-scheduler-appointment
      @onDelete="deleteDealDate"
      @onUpdate="updateDealDate"
      :deal-date="dealDateLocal"/>
    <base-alert type="warning" class="text-center" v-if="!sales || sales.length === 0">
      {{ $t('callcenter.sales_not_attached_to_current_campaign') }}
    </base-alert>
    <DxScheduler
      :height="schedulerHeight"
      v-if="showScheduler && sales && sales.length > 0"
      :time-zone="schedulerOptions.timeZone"
      :cell-duration="schedulerOptions.cellDuration"
      :start-day-hour="schedulerService.getStartDayHour()"
      :end-day-hour="schedulerService.getEndDayHour()"
      :date-serialization-format="schedulerOptions.dateSerializationFormat"
      :maxAppointmentsPerCell="schedulerOptions.maxAppointmentsPerCell"
      :groups="schedulerOptions.groups"
      :scrolling="schedulerOptions.scrolling"
      :first-day-of-week="schedulerOptions.firstDayOfWeek"
      :views="schedulerOptions.views"
      :current-view="schedulerOptions.currentView"
      :current-date="currentDate"
      :data-source="dataSource"
      :show-current-time-indicator="schedulerOptions.showCurrentTimeIndicator"
      :editing="editing"
      :min="dateMin"
      :max="dateMax"
      :onAppointmentAdded="appointmentAdded"
      :onAppointmentAdding="appointmentAdding"
      :onAppointmentClick="appointmentClick"
      :onAppointmentContextMenu="appointmentContextMenu"
      :onAppointmentDblClick="appointmentDblClick"
      :onAppointmentDeleted="appointmentDeleted"
      :onAppointmentDeleting="appointmentDeleting"
      :onAppointmentFormOpening="appointmentFormOpening"
      :onAppointmentRendered="appointmentRendered"
      :onAppointmentUpdated="appointmentUpdated"
      :onAppointmentUpdating="appointmentUpdating"
      :onCellClick="cellClick"
      :onCellContextMenu="cellContextMenu"
      :onContentReady="onContentReady"
      :onDisposing="disposing"
      :onInizialized="initialized"
      :onOptionChanged="optionChanged"
      appointment-template="AppointmentTemplateSlot"
      ref="dxSchedulerOperator"
    >
      <DxResource
        :allow-multiple="false"
        :data-source="resourcesData"
        field-expr="userId"
        label="Sale"
      />

      <DxResource
        :allow-multiple="false"
        :data-source="statusData"
        :use-color-as-default="true"
        field-expr="status"
        label="Status"
      />

      <template #AppointmentTemplateSlot="{ data }">
        <div class="position-relative">
          <div>{{ data | optional('appointmentData.text') }}</div>
          <div class="small">
            {{ data | optional('appointmentData.startDate') | time }} -
            {{ data | optional('appointmentData.endDate') | time }}
          </div>
        </div>
      </template>
    </DxScheduler>
  </div>
</template>

<script>

import {DxResource, DxScheduler} from "devextreme-vue/scheduler";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Modal from "@/components/Modal";
import {DatePicker, Option, Select, TimeSelect} from "element-ui";
import DealDateSchedulerMixin from "@/components/DealDateScheduler/DealDateSchedulerMixin";
import {schedulerService} from "@/util/scheduler-service";
import DealDateSchedulerLegend from "@/components/DealDateScheduler/DealDateSchedulerLegend";
import DealDateSchedulerAppointment from "@/components/DealDateScheduler/DealDateSchedulerAppointment";
import {mapGetters, mapMutations} from "vuex";
import {endpoints} from "@/routes/endpoints";
import DealDate from "@/models/dealDate";
import BaseAlert from "@/components/BaseAlert";

export default {
  name: "DealDateOperatorScheduler",
  components: {
    DealDateSchedulerAppointment,
    DealDateSchedulerLegend,
    ListGroupItemComponent,
    Modal,
    BaseAlert,
    DxScheduler,
    DxResource,
    [TimeSelect.name]: TimeSelect,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [DealDateSchedulerMixin],
  computed: {
    ...mapGetters({
      callCenterLead: 'call-center-operator/callCenterLead',
    }),
  },

  methods: {

    ...mapMutations({
      setSaleAppointment: 'call-center-operator/setSaleAppointment',
    }),

    async getData(dateFrom, dateTo) {
      try {
        const queryParams = new URLSearchParams({ 'start': dateFrom });

        if (dateTo) {
          queryParams.append('end', dateTo)
        }

        queryParams.append('sale_ids', this.$_.join(this.$_.map(this.sales, sale => sale.id), ','))

        const resp = await this.$api.get(endpoints.DEAL_DATE_OPERATOR_INDEX_DIARY + '?' + queryParams.toString());

        return this.$_.map(resp?.data?.data, (item) => this.parseDealDate(item));
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        return [];
      }
    },

    async cellClick(event) {

      this.scheduler.option('disabled', true);

      const update = !!this.dealDateLocal.id;

      if (update) {
        this.dataSource = this.$_.filter(this.dataSource, (item) => {
          return item.id !== this.dealDateLocal.id;
        });
      }

      this.setDealDateLocalFromEventClick(event);

      this.dataSource.push(this.parseDealDate(this.dealDateLocal));
      this.currentTime = event.cellData.startDate;

      try {
        if (update) {
          const resp = await this.$api.put(endpoints.DEAL_DATE_UPDATE.replace('{id}', this.dealDateLocal.id), this.dealDateLocal);
          this.setSaleAppointment(resp?.data?.data || null);
        } else {
          this.dealDateLocal.dateable_id = this.callCenterLead.id;
          const resp = await this.$api.post(endpoints.DEAL_DATE_STORE_BY_CALL_CENTER_LEAD, this.dealDateLocal);
          this.setSaleAppointment(resp?.data?.data || null);
          this.$_.each(this.dataSource, (item) => {
            if (item.id === this.dealDateLocal.id) {
              item.id = resp?.data?.data?.id;
              item.status = 'confirmed';
              item.disabled = false;
            }
          });
          this.$set(this.dealDateLocal, 'id', resp?.data?.data?.id);
        }
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.scheduler.option('disabled', false);
      }
    },

    deleteDealDate(dealDate) {
      this.scheduler.option('disabled', true);
      this.dataSource = this.$_.filter(this.dataSource, (item) => {
        return item.id !== dealDate.id;
      });
      this.$api.delete(endpoints.DEAL_DATE_DELETE.replace('{id}', dealDate.id))
        .then(() => {
          if (this.dealDateLocal.id === dealDate.id) {
            this.dealDateLocal = this.$_.cloneDeep(DealDate);
            this.setSaleAppointment(this.dealDateLocal);
          }
          this.$notify({type: 'success', message: this.$t('notifications.deleted_success')});
        }).catch(() => {
        this.dataSource.push(this.parseDealDate(dealDate));
        this.$notify({type: 'error', message: this.$t('notifications.deleted_error')});
      })
        .finally(() => {
          this.scheduler.option('disabled', false);
        })
    },


    parseDealDate(item) {
      const event = schedulerService.parseDealDate(item);
      event.disabled = this.dealDateLocal.id !== item.id;
      event.status = event.disabled ? 'disabled' : 'confirmed';
      return event;
    },


  },

}
</script>

<style scoped>

</style>
