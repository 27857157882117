<template>

  <ul class="list-group list-group-flush">
    <li class="standard-li">

      <base-input class="my-1 flex-grow-1">
        <el-select
          class="select-default text-uppercase"
          :placeholder="$t('fields.status')"
          v-model="status"
        >
          <el-option
            v-for="option in statusOptions"
            class="select-default text-uppercase"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          >
          </el-option>
        </el-select>
      </base-input>


    </li>
    <li class="standard-li">

      <base-input class="my-1 flex-grow-1">
        <el-select
          class="select-default text-uppercase"
          :placeholder="$t('fields.substatus')"
          v-model="substatus"
          @change="saveChangeStatus"
        >
          <el-option
            v-for="option in subStatusOptions"
            class="select-default text-uppercase"
            :value="option.value"
            :label="option.label"
            :key="option.value"
          >
          </el-option>
        </el-select>
      </base-input>


    </li>
  </ul>

</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Option, Select} from "element-ui";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ChangeLeadStatus",
  components: {
    OctoIcon,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      status: null,
      substatus: null,
      leadStatus: LeadStatuses
    }
  },

  watch: {
    status: function (val) {
      this.substatus = null;
      this.setSubStatusTo(null);
    },
  },

  computed: {

    ...mapGetters({
      statusTo: 'call-center-operator/statusTo',
      subStatusTo: 'call-center-operator/subStatusTo',
    }),

    statusOptions: function () {

      const options = [];
      this.$_.each(this.leadStatus, (item) => {
        if (item.callCenterChoosable) {
          options.push({
            value: item.value,
            label: this.$t('callcenter.status.' + item.value)
          });
        }
      })
      return options;
    },

    subStatusOptions: function () {
      if (!this.leadStatus.hasOwnProperty(this.status)) {
        return []
      }

      return this.$_.map(this.leadStatus[this.status].substatuses, (item) => {
        return {
          value: item.value,
          label: this.$t('callcenter.status.' + item.value)
        }
      });
    }
  },

  mounted() {
    this.status = this.statusTo;
    this.substatus = this.subStatusTo;
  },
  methods: {
    ...mapMutations({
      setStatusTo: 'call-center-operator/setStatusTo',
      setSubStatusTo: 'call-center-operator/setSubStatusTo'
    }),

    saveChangeStatus: function () {
      this.setStatusTo(this.status);
      this.setSubStatusTo(this.substatus);
    }
  }
}
</script>

<style scoped>

</style>
