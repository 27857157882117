<template>
  <div>
    <h4 class="text-center mb-0">
      <label-theme-component>
        {{ textRecall ? $t('callcenter.infoProcessing.' + textRecall) : 'N.D.' }}
      </label-theme-component>
    </h4>
    <el-date-picker
      class="mt-3"
      v-model="deadlineLocal"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm"
      format="dd/MM/yyyy HH:mm"
      placeholder="Select date and time"
      @change="saveDeadline"
      default-time="12:00:00"
    />
  </div>
</template>

<script>

import {DatePicker} from 'element-ui';
import {mapGetters, mapMutations} from "vuex";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";
import LabelThemeComponent from "../../../components/LabelThemeComponent";

export default {
  name: "OperatorWizardStepThreeRecallDeadline",
  components: {
    LabelThemeComponent,
    [DatePicker.name]: DatePicker
  },
  data() {
    return {
      deadlineLocal: null,
      leadStatus: LeadStatuses,

    }
  },

  watch: {
    deadline: function (val) {
      this.deadlineLocal = val;
    }
  },

  computed: {
    ...mapGetters({
      subStatusTo: 'call-center-operator/subStatusTo',
      deadline: 'call-center-operator/deadline',
    }),

    textRecall: function () {
      switch (this.subStatusTo) {
        case this.leadStatus.processing.substatuses.public_recall.value:
          return 'public_recall';
        case this.leadStatus.processing.substatuses.private_recall.value:
          return 'private_recall';
        default:
          return null

      }
    }
  },

  methods: {
    ...mapMutations({
      setDeadline: 'call-center-operator/setDeadline',
    }),

    saveDeadline: function (value) {
      this.setDeadline(value);
    }
  }
}
</script>

<style scoped>

</style>
