<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label="$t('callcenter.sale')" :value="saleFullname" />
    <list-group-item-component :label="$t('fields.date')" :value="appointmentDate"/>
    <list-group-item-component :label="$t('callcenter.time')" :value="appointmentTime"/>
  </ul>
</template>

<script>

import {mapGetters} from "vuex";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "OperatorWizardStepThreeAppointmentConfirmed",
  components: {ListGroupItemComponent},
  computed: {
    ...mapGetters({
      saleAppointment: 'call-center-operator/saleAppointment',
      users: 'common/users',
    }),

    saleFullname: function() {
      return (this.users[this.saleAppointment.user_id]?.firstname
          + ' ' + this.users[this.saleAppointment.user_id]?.lastname)
    },

    appointmentDate: function () {
      return this.saleAppointment.date_start
      ? this.$moment(this.saleAppointment.date_start).format("DD/MM/YYYY")
        : 'N.D.'
    },

    appointmentTime: function () {
      return (this.saleAppointment.date_start && this.saleAppointment.date_end)
        ? ( this.$moment(this.saleAppointment.date_start).format("HH:mm")
          + " - "
          + this.$moment(this.saleAppointment.date_end).format("HH:mm") )
        : 'N.D.'
    }
  },
}
</script>

<style scoped>

</style>
