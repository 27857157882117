<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush">
        <list-group-title-section-component :label="$t('callcenter.operator_data')"/>
        <list-group-item-component
          v-for="(value, key) in userData"
          v-bind:key="`custom-field-${key}`"
          :label="$t(`fields.${key}`)"
          :value="value"
        />
        <list-group-title-section-component :label="$t('callcenter.available_campaigns')"/>
        <list-group-item-component
          v-for="(campaign, key) in campaigns"
          v-bind:key="`ccampaign-${key}`"
          :label="campaign.name"
          :value="campaign.call_center_leads_count"
        />
      </ul>
    </div>
    <div class="col-12 col-md-6">
      <ul class="list-group list-group-flush">
        <list-group-title-section-component :label="$t('callcenter.call_center_lead_private_recalls')"/>
      </ul>
      <no-data-component class="mt-2" :label="$t('common.no_results')" v-if="callCenterLeads.length === 0"/>
      <ul class="list-group list-group-flush max-height-list">
        <list-group-item-component
          v-for="(callCenterLead, key) in callCenterLeads"
          v-bind:key="`private-recalls-${key}`"
          :value="callCenterLead.deadline | datetime"
          :label="`${$options.filters.optional(callCenterLead, 'lead.registry.surname')} ` +
                  `${$options.filters.optional(callCenterLead, 'lead.registry.name')}`"
        />
      </ul>
    </div>
  </div>
</template>

<script>

import BaseAlert from "@/components/BaseAlert";
import {endpoints} from "@/routes/endpoints";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import NoDataComponent from "@/components/NoDataComponent";

export default {
  name: "OperatorData",
  components: {NoDataComponent, ListGroupTitleSectionComponent, ListGroupItemComponent, BaseAlert},
  data() {
    return {
      campaigns: [],
      userData: {
        firstname: '',
        lastname: '',
        username: ''
      },
      callCenterLeads: []
    }
  },
  mounted() {
    this.$api.get(endpoints.CAMPAIGN_BY_OPERATOR)
      .then((resp) => {
        this.campaigns = resp?.data?.campaigns || [];
        this.userData.firstname = resp?.data?.data?.firstname || 'N.D.';
        this.userData.lastname = resp?.data?.data?.lastname || 'N.D.';
        this.userData.username = resp?.data?.data?.username || 'N.D.';
      });

    this.$api.get(endpoints.CALL_CENTER_GET_PRIVATE_RECALLS)
      .then((resp) => {
        this.callCenterLeads = resp?.data;
      });
  }
}

</script>

<style scoped>
.max-height-list {
  max-height: 200px;
  overflow: auto;
}
</style>
