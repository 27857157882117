<template>
  <div>
    <card body-classes="standard-card-body" class="shadow">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('callcenter.organizer') }}</h4>
      </div>
      <deal-date-operator-scheduler
        :enable-statuses="false"
        :operator="currentUser"
        :dealdate="{}"
        :date-min="dateMin"
        :date-max="dateMax"
        :sales="sales"
        :registry="registry"
      />
    </card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DealDateOperatorScheduler from "@/components/DealDateScheduler/DealDateOperatorScheduler";

export default {
  name: "LeadScheduler",
  components: {
    DealDateOperatorScheduler
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      sales: 'call-center-operator/sales',
      settings: 'common/settings',
      lead: 'call-center-operator/lead',
      registry: 'call-center-operator/registry',
      users: 'common/users',
      currentUser: 'auth/currentUser',
      callCenterLead: 'call-center-operator/callCenterLead',
    }),

    dateMin() {
      return this.$moment().toDate();
    },
    dateMax() {
      return this.$moment().add(this.settings?.callcenter_calendar_day || 3, 'days').toDate();
    },

    currentLead: function () {
      const lead = this.$_.cloneDeep(this.lead);
      lead.registry = this.$_.cloneDeep(this.registry)
      return lead;
    }
  },

  methods: {

  }
}
</script>

<style scoped>

</style>
